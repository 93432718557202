
import { computed, defineComponent, getCurrentInstance, onMounted, watchEffect } from 'vue'
import china from './json/china.json'

export default defineComponent({
  name: 'DomesticMapCharts',
  props: ['data', 'slider'],
  setup (props) {
    const { proxy }: any = getCurrentInstance()
    const mapData = computed(() => {
      return props.data
    })
    onMounted(() => {
      const domesticMapCharts = proxy.$echarts.init(document.getElementById('domestic-map-charts'))
      const render = () => {
        proxy.$echarts.registerMap('china', china)
        domesticMapCharts.setOption({
          tooltip: {
            formatter: function (params: any) {
              return `${params.name}：${isNaN(params.value) ? '-' : params.value + '次'}`
            } // 数据格式化
          },
          roam: 'move',
          layoutCenter: ['50%', '60%'],
          // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
          layoutSize: '100%',
          visualMap: {
            type: 'continuous',
            min: 0, // 最小值
            max: 5000, // 最大值
            show: true, // 图注是否显示
            text: ['高', '低'],
            orient: 'horizontal',
            itemWidth: 20,
            itemHeight: 100,
            calculable: true,
            left: 0,
            align: 'left',
            hoverLink: true,
            inRange: {
              color: ['#D4EEF9', '#E6A23C', '#F56C6C'],
              symbolSize: [100, 100]
            }
          },
          series: [
            {
              type: 'map',
              mapType: 'china',
              data: mapData.value,
              zoom: props.slider,
              itemStyle: {
                normal: {
                  borderWidth: '2',
                  borderColor: 'rgba(255, 255, 255, 0.3)'
                },
                emphasis: {
                  areaColor: '#78DFFC' // 鼠标选择区域颜色
                }
              }
            }
          ]
        })
      }
      watchEffect(() => {
        render()
      })
      setTimeout(() => {
        domesticMapCharts.resize()
        window.addEventListener('resize', () => domesticMapCharts.resize())
        // window.onresize = () => {
        //   mapCharts.resize()
        // }
      }, 200)
    })
  }
})
